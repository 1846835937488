<template>
  <div id="FQCCharttwo" style="height: 100%; width: 100%;"></div>
</template>

<script>
export default {
  props: ['Getrunt'],
  data () {
    return {
      option: {
        title: {
          text: this.$t('All.系统统计日期范围内数量（检验员）'),
          left: 'center'
        },
        grid: {
          left: '3%',
          right: '4%',
          bottom: '3%',
          containLabel: true
        },
        xAxis: {
          type: 'category',
          data: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun']
        },
        yAxis: {
          type: 'value'
        },
        series: [
          {
            data: [120, 200, 150, 80, 70, 1000, 500],
            type: 'bar',
            label: {
              show: true,
              position: 'top'
            }
          }
        ]
      },
      myChart: null
    }
  },
  mounted () {
    const now = new Date()
    const year = now.getFullYear()
    const month = now.getMonth()

    // 当前月份的第一天
    const monthStart = this.formatDate(new Date(year, month, 1))

    // 当前月份的最后一天
    const monthEnd = this.formatDate(new Date(year, month + 1, 0))
    this.Getdata(this.$store.state.Login.Factory === 'All' ? 'TC' : this.$store.state.Login.Factory, monthStart, monthEnd)
    window.addEventListener('resize', this.resizechart)
  },
  methods: {
    resizechart () {
      if (this.myChart !== null && this.myChart !== undefined) {
        this.myChart.resize()
      }
    },
    // 格式化函数，确保月份和日期为两位数
    formatDate (date) {
      const y = date.getFullYear()
      const m = (date.getMonth() + 1).toString().padStart(2, '0') // 月份加 1，确保两位数
      const d = date.getDate().toString().padStart(2, '0') // 确保日期是两位数
      return `${y}-${m}-${d}`
    },
    async Getdata (cq, monthStart, monthEnd) {
      try {
        this.myChart = this.$echarts.init(document.getElementById('FQCCharttwo'))
        this.myChart.resize()
        this.myChart.clear()
        const { data: res } = await this.$http.post(`api/Newiqc/Getiqcallusersum?Factory=${cq}&Type=NewFQC&Start=${monthStart}&End=${monthEnd}`)
        if (res.status !== 200) return this.$message.error(res.msg)
        this.option.series = res.response.series
        this.option.xAxis.data = res.response.names
        this.myChart.setOption(this.option, true)
        const vthsi = this
        this.myChart.on('click', function (params) {
          // 控制台打印数据的名称
          vthsi.Getrunt(params.name)
        })
      } catch (error) {
        return this.$message.error(error)
      }
    }
  }
}
</script>
